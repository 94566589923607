<template>
  <div
    v-if="card"
    class="signup-card flex flex-col sm:flex-row justify-between px-[32px] md:px-[64px] py-[32px] xl:py-0 gap-4 sm:gap-8 overflow-hidden xl:h-[76px]"
  >
    <div class="flex flex-col items-center hidden xl:block">
      <StoryblokImage :image="card.image" :width="89" />
    </div>
    <div class="flex flex-col justify-center text-canvas basis-2/3">
      <RichText :text="card.content" />
    </div>
    <div class="flex flex-col justify-center items-center vvd-theme-alternate">
      <NuxtLink :href="VonageBlogLandingPageSignUpURL" type="button">
        <VButton
          id="signup"
          :label="card.buttonLabel"
          :appearance="isVonageBrand ? 'filled' : 'outlined'"
          :connotation="!isVonageBrand ? 'cta' : ''"
          :icon="card.buttonIcon"
          icon-trailing
        />
      </NuxtLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import { VButton } from '@vonage/vivid-vue';
import StoryblokImage from '@/components/storyblok/StoryblokImage.vue';
import RichText from '@/components/rich-text/RichText.vue';
import type { PropType } from 'vue';
import type { BlogSignUpCard } from '@/types/storyblok';
import { VonageBlogLandingPageSignUpURL } from '@/constants';
import { useBrandingStore } from '@/store/branding';
import { computed } from 'vue';

defineProps({
  card: { type: Object as PropType<BlogSignUpCard>, required: true },
});

const brandingStore = useBrandingStore();
const isVonageBrand = computed(() => brandingStore.data?.vendorId === 'vonage');
</script>

<style lang="scss" scoped>
.signup-card {
  background: var(--dp-blog-landing-page-signup);

  :deep(h4) {
    margin: 0;
    font: var(--vvd-typography-heading-5);
  }

  :deep(p) {
    margin: 0;
    font: var(--vvd-typography-base-light);
  }
}
</style>
